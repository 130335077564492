
  import * as React from 'react';
import {brand} from '@workday/canvas-tokens-web';

import {ColorGrid, buildColorSwatch} from './TokensColorsGrid';

const palettes = Object.keys(brand).map(key => {
  const tokens = brand[key as keyof typeof brand];
  const values = Object.entries(tokens).map(([value, varName]) =>
    buildColorSwatch(varName, `brand.${key}.${value}`)
  );
  return {
    name: key,
    values,
  };
});

export const BrandTokens = () => {
  return (
    <div className="cnvs-site-stack">
      {palettes.map(palette => (
        <ColorGrid key={palette.name} name={palette.name} palette={palette.values} />
      ))}
    </div>
  );
};

  BrandTokens.__RAW__ = "import * as React from 'react';\nimport {brand} from '@workday/canvas-tokens-web';\n\nimport {ColorGrid, buildColorSwatch} from './TokensColorsGrid';\n\nconst palettes = Object.keys(brand).map(key => {\n  const tokens = brand[key as keyof typeof brand];\n  const values = Object.entries(tokens).map(([value, varName]) =>\n    buildColorSwatch(varName, `brand.${key}.${value}`)\n  );\n  return {\n    name: key,\n    values,\n  };\n});\n\nexport const BrandTokens = () => {\n  return (\n    <div className=\"cnvs-site-stack\">\n      {palettes.map(palette => (\n        <ColorGrid key={palette.name} name={palette.name} palette={palette.values} />\n      ))}\n    </div>\n  );\n};\n";