import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import colorsJson from '@workday/canvas-colors-web/dist/hq/CanvasHQ.json';
import { ExternalHyperlink } from '@workday/canvas-kit-react/button';
import { InformationHighlight } from '@workday/canvas-kit-docs';
import { ColorGrid } from '../../../src/components/content/tokens/ColorGrid';
import { BrandTokens } from '../../../src/components/content/tokens/TokenBrandGrid';
import { getInternalExternalURL } from '../../../src/utils/url';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PackageInfo = makeShortcode("PackageInfo");
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <InformationHighlight mdxType="InformationHighlight">
  <InformationHighlight.Icon style={{
        width: '24px'
      }} />
  <InformationHighlight.Heading>Looking for our old color tokens?</InformationHighlight.Heading>
  <InformationHighlight.Body>
    While we still support our old tokens, we’re migrating our documentation and usage guidance to
    reflect our new Tokens Web. It is <strong>not</strong> currently a requirement to update all
    your tokens to use v11, but we <strong>strongly</strong> encourage you to begin migrating as you
    can. If you're an engineer looking to migrate to our new tokens, we wrote a handy{' '}
    <ExternalHyperlink href={'https://github.com/Workday/canvas-tokens/discussions/77'} mdxType="ExternalHyperlink">
      migration guide
    </ExternalHyperlink>
    for you.
  </InformationHighlight.Body>
  <InformationHighlight.Link href={getInternalExternalURL('/v10/tokens/color')}>
    View v10 Color Token Docs.
  </InformationHighlight.Link>
    </InformationHighlight>
    <PackageInfo storybook="https://workday.github.io/canvas-tokens/?path=/docs/docs-base-tokens--docs" figma="https://www.figma.com/design/GmIqhQ7KqX6WQZFimdn756/Canvas-Tokens-v2?node-id=21675-7282&t=CVIqFlO1Vep4R0fj-4" package="@workday/canvas-tokens-web" mdxType="PackageInfo" />
    <h2 {...{
      "id": "usage-guidance"
    }}>{`Usage Guidance`}</h2>
    <p>{`Our color choices promote visual clarity, making it easy for users to find what they need. We use a
consistent palette to create a seamless and intuitive experience across all sections of the
application.`}</p>
    <p>{`To learn more about tokens in general, go to `}<a parentName="p" {...{
        "href": "/styles/tokens/overview"
      }}>{`Tokens Overview page`}</a>{`.`}</p>
    <h3 {...{
      "id": "general-brand-colors"
    }}>{`General Brand Colors`}</h3>
    <p>{`From backgrounds, to selection, to icons, these brand colors apply to most pages in the Workday
product.`}</p>
    <BrandTokens mdxType="BrandTokens" />
    <h2 {...{
      "id": "system-color-tokens"
    }}>{`System Color Tokens`}</h2>
    <p>{`System tokens are themed variables intended to provide application-wide theming. They are not
connected to `}<a parentName="p" {...{
        "href": "#Brand-Color-Tokens"
      }}>{`Brand tokens`}</a>{`, which are tenant-specific theming.`}</p>
    <p><a parentName="p" {...{
        "href": "https://workday.github.io/canvas-tokens/?path=/docs/docs-system-tokens-overview--docs"
      }}>{`View our system color tokens`}</a></p>
    <h3 {...{
      "id": "background"
    }}>{`Background`}</h3>
    <p>{`From backgrounds, to selection, to icons, these general colors apply to most pages in the Workday
product.`}</p>
    <p><a parentName="p" {...{
        "href": "https://workday.github.io/canvas-tokens/?path=/docs/docs-system-tokens-color-background--docs"
      }}>{`View our background color tokens`}</a></p>
    <h3 {...{
      "id": "text"
    }}>{`Text`}</h3>
    <p>{`Use these color tokens to color text such as body, label, or heading. For more information on how
typography is used in Workday, check out `}<a parentName="p" {...{
        "href": "/styles/tokens/type"
      }}>{`Type token page`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://workday.github.io/canvas-tokens/?path=/docs/docs-system-tokens-color-text--docs"
      }}>{`View our text color tokens`}</a></p>
    <h3 {...{
      "id": "icon"
    }}>{`Icon`}</h3>
    <p>{`Use these color tokens for System Icons only. Accent and Applet Icons are considered illustrations
and could use either Background or `}<a parentName="p" {...{
        "href": "#Static"
      }}>{`Static Color Tokens`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://workday.github.io/canvas-tokens/?path=/docs/docs-system-tokens-color-icon--docs"
      }}>{`View our icon color tokens`}</a></p>
    <h3 {...{
      "id": "foreground"
    }}>{`Foreground`}</h3>
    <p>{`Use foreground color or “fg” for short when both text and icon in a component must change color
value together. They’re currently used in `}<a parentName="p" {...{
        "href": "/components/buttons/button"
      }}>{`buttons`}</a>{`,
`}<a parentName="p" {...{
        "href": "/components/buttons/segmented-control"
      }}>{`segmented controls`}</a>{`,
`}<a parentName="p" {...{
        "href": "/components/containers/table"
      }}>{`table cells`}</a>{`,
`}<a parentName="p" {...{
        "href": "/components/indicators/status-indicator"
      }}>{`status indicators`}</a>{`,
`}<a parentName="p" {...{
        "href": "/components/indicators/banner"
      }}>{`banners`}</a>{`, and `}<a parentName="p" {...{
        "href": "/components/containers/tabs"
      }}>{`tabs`}</a>{` components.`}</p>
    <p>{`Some of our Canvas components, such as `}<a parentName="p" {...{
        "href": "/components/popups/menu"
      }}>{`menu`}</a>{`,
`}<a parentName="p" {...{
        "href": "/components/inputs/search-input"
      }}>{`search input`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/inputs/prompt-input"
      }}>{`prompt inputs`}</a>{`,
`}<a parentName="p" {...{
        "href": "/components/popups/toast"
      }}>{`toasts`}</a>{`, and `}<a parentName="p" {...{
        "href": "/components/indicators/pill"
      }}>{`pills`}</a>{`, use separate colors
for text and icons.`}</p>
    <p><a parentName="p" {...{
        "href": "https://workday.github.io/canvas-tokens/?path=/docs/docs-system-tokens-color-foreground--docs"
      }}>{`View our foreground color tokens`}</a></p>
    <h3 {...{
      "id": "border"
    }}>{`Border`}</h3>
    <p>{`These are used for border colors only.`}</p>
    <p><a parentName="p" {...{
        "href": "https://workday.github.io/canvas-tokens/?path=/docs/docs-system-tokens-color-border--docs"
      }}>{`View our border color tokens`}</a></p>
    <h3 {...{
      "id": "shadow"
    }}>{`Shadow`}</h3>
    <p>{`The default color for drop-shadows.`}</p>
    <p><a parentName="p" {...{
        "href": "https://workday.github.io/canvas-tokens/?path=/docs/docs-system-tokens-color-shadow--docs"
      }}>{`View our shadow color tokens`}</a></p>
    <h3 {...{
      "id": "static"
    }}>{`Static`}</h3>
    <p>{`Use the following colors to denote the status of an object, task, or flow. If you're interested in
displaying the status of something, check out the
`}<a parentName="p" {...{
        "href": "/components/indicators/status-indicator"
      }}>{`Status Indicator component`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: These colors are intended for decorative purposes only. Use these when the Brand and System Colors
are not sufficient. Be careful using these for text, as some colors do not pass contrast ratio
guidelines.`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://workday.github.io/canvas-tokens/?path=/docs/docs-system-tokens-color-static--docs"
      }}>{`View our static color tokens`}</a></p>
    <h2 {...{
      "id": "brand-color-tokens"
    }}>{`Brand Color Tokens`}</h2>
    <p>{`Brand tokens are themed variables designated for brand / tenant-level customization. They are not
connected to system tokens, which are intended to be application-wide. Brand tokens are used for our
buttons, focus rings, errors, inputs, and banners.`}</p>
    <p><a parentName="p" {...{
        "href": "https://workday.github.io/canvas-tokens/?path=/docs/docs-brand-tokens--docs"
      }}>{`View our brand color tokens`}</a></p>
    <h2 {...{
      "id": "web-examples"
    }}>{`Web Examples`}</h2>
    <p>{`Canvas Tokens can be consumed as either JavaScript variables or as CSS variables and class names.
The JavaScript token values reference CSS variable names, so if you're using JS tokens, you'll also
need to import the CSS variables in your application.`}</p>
    <h3 {...{
      "id": "javascript--typescript"
    }}>{`Javascript / Typescript`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`// styles.ts
import {system} from '@workday/canvas-tokens-web';

const styles = {
  backgroundColor: \`var(\${system.color.bg.default})\`,
};
`}</code></pre>
    <h3 {...{
      "id": "css"
    }}>{`CSS`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`// styles.css
@import '@workday/canvas-tokens-web/css/system/_variables.css';
.card {
  background-color: var(--cnvs-sys-color-bg-default);
}
`}</code></pre>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "ios-examples"
      }}>{`iOS Examples`}</h2>
      <p>{`Coming soon!`}</p>
      <h2 {...{
        "id": "android-examples"
      }}>{`Android Examples`}</h2>
      <p>{`Coming soon!`}</p>
    </InternalContent>
    <h2 {...{
      "id": "all-colors"
    }}>{`All Colors`}</h2>
    <p>{`Hover over a color or use your keyboard to tab to it to access a button which can be pressed to copy
the hexadecimal code for that color to your clipboard.`}</p>
    <ColorGrid colors={colorsJson} mdxType="ColorGrid" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      