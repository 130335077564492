
  import React from 'react';
import styled from '@emotion/styled';
import {ColorSet, ColorSetProps} from './ColorSet';
import {space} from '@workday/canvas-kit-react/tokens';

const Grid = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  fontWeight: 600,
  marginTop: space.xs,
});

export const ColorGrid = ({colors}: {colors: {rows: ColorSetProps['set'][]}}) => (
  <Grid className="exclude-anchor-nav">
    {colors.rows.map((set: ColorSetProps['set'], index: number) => {
      return <ColorSet set={set} key={index} />;
    })}
  </Grid>
);

export default ColorGrid;

  ColorGrid.__RAW__ = "import React from 'react';\nimport styled from '@emotion/styled';\nimport {ColorSet, ColorSetProps} from './ColorSet';\nimport {space} from '@workday/canvas-kit-react/tokens';\n\nconst Grid = styled('div')({\n  display: 'flex',\n  justifyContent: 'space-between',\n  flexWrap: 'wrap',\n  fontWeight: 600,\n  marginTop: space.xs,\n});\n\nexport const ColorGrid = ({colors}: {colors: {rows: ColorSetProps['set'][]}}) => (\n  <Grid className=\"exclude-anchor-nav\">\n    {colors.rows.map((set: ColorSetProps['set'], index: number) => {\n      return <ColorSet set={set} key={index} />;\n    })}\n  </Grid>\n);\n\nexport default ColorGrid;\n";